.page {
  margin: 0;
  padding: 0;
  padding-left: 5mm;
  padding-top: 5mm;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  min-width: 29.7cm;
  min-height: 21cm;
}

.label {
  display: flex;
  justify-content: flex-end;
  min-width: 141mm;
  min-height: 20mm;
  margin-right: 5mm;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 3mm;
  padding-left: 2mm;
  width: 79mm;
}

@page {
  size: A4 landscape;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
